"use client"

import { Suspense, useRef } from "react"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { marketCookie } from "~/components/ui/MarketPopin/utils"
import Wrapper, { type TWrapper } from "~/components/ui/MarketPopin/Wrapper"
import { Popin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type MarketPopinProps = PropsWithClassName<TWrapper>

function MarketPopin({ className, type, onRemove }: MarketPopinProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleOnRemove = () => {
    marketCookie.hasInteracted.set(true)
    onRemove?.()
  }

  return (
    <Popin
      className={clsx(className, css.MarketPopin)}
      zIndex={50}
      bgColor="black/30"
      clickOutsideRef={wrapperRef}
      onRemove={handleOnRemove}
    >
      <Suspense>
        <Wrapper ref={wrapperRef} className={clsx(css.wrapper)} type={type} onRemove={handleOnRemove} />
      </Suspense>
    </Popin>
  )
}

export default MarketPopin
