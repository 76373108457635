import marketsConfig from "config/markets.json"

const marketsOrder = ["France", "International", "CH", "UK", "US"]

export function getAllZones() {
  // rework zones ordering based on config array
  return Object.entries(marketsConfig)
    .sort(([a], [b]) => {
      return marketsOrder.indexOf(a) - marketsOrder.indexOf(b)
    })
    .flatMap(([, countries]) => [countries[0]])
}
