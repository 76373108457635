"use client"

import { useHydrateAtoms } from "jotai/utils"

import type { TCardTags } from "~/managers/CardTagsManager/_data/serializer"
import { cardTagsAtom } from "~/managers/CardTagsManager/store"

export function CardTagsManager({ cardTags = [] }: { cardTags: TCardTags }) {
  useHydrateAtoms([[cardTagsAtom, cardTags]], { dangerouslyForceHydrate: true })
  return <></>
}
