import i18nConfig from "~/config/i18n.json"
import { getIntl, getLang, getMarketByCountry } from "~/lib/i18n/utils/get-i18n"
import { capitalize } from "~/utils/capitalize"

export function getLanguagesOptionsByCountry(selectedCountry: string) {
  const localesByCountry = Object.entries(i18nConfig)
    .filter(([_, value]) => {
      return getMarketByCountry(selectedCountry) === value.market
    })
    // // Sort by default country locale
    // ?.sort(([, { default_country_locale: a }], [, { default_country_locale: b }]) => {
    //   return a === b ? 0 : a ? -1 : 1
    // })
    .map(([locale]) => locale)

  return localesByCountry.flatMap((locale) => {
    const intl = getIntl(locale)
    const lang = getLang(locale)

    const languageNames = new Intl.DisplayNames(intl, { type: "language" })

    const label = languageNames.of(lang)
    if (!label) {
      return []
    }

    return {
      label: capitalize(label),
      value: lang,
    }
  })
}
