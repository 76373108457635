import type { NavigationDataProps } from "@unlikelystudio/react-abstract-components"

import { type TNavigation } from "~/components/globals/Navigation/_data/serializer"
import getAlternateUrl from "~/utils/alternate-url"

export const serializeNavigationJsonLd = (items: NonNullable<TNavigation>["links"] | null): NavigationDataProps => {
  const processed =
    items
      ?.map(({ link, sublinks }) => {
        const subLinks = sublinks
          ?.map(({ link, linksLists }) => {
            return {
              name: link?.children ?? undefined,
              link:
                link && "href" in link
                  ? {
                      href: getAlternateUrl(link.href),
                    }
                  : undefined,
              items: linksLists
                ?.map(({ links }) => {
                  return links?.map(({ link }) => {
                    return {
                      name: link?.children ?? undefined,
                      link:
                        link && "href" in link
                          ? {
                              href: getAlternateUrl(link.href),
                            }
                          : undefined,
                    }
                  })
                })
                ?.flat(2),
            }
          })
          ?.flat(3)

        return {
          name: link?.children ?? undefined,
          link:
            link && "href" in link
              ? {
                  href: getAlternateUrl(link.href),
                }
              : undefined,
          items: subLinks,
        }
      })
      ?.filter(Boolean) ?? []

  return {
    items: processed,
  }
}
