import type { ItemNavProps, NavigationDataProps } from "./index"

export default function serializeJSONLD({ items }: NavigationDataProps) {
  const firstLevelItems = items?.map((item) => item) ?? []
  const secondLevelItems =
    firstLevelItems?.reduce<ItemNavProps[]>((prev, curr) => {
      if (curr?.items) return prev.concat(curr.items)
      else return prev
    }, [] as ItemNavProps[]) ?? []
  const thirdLevelItems =
    secondLevelItems?.reduce((prev, curr) => {
      if (curr?.items) return prev.concat(curr.items)
      else return prev
    }, [] as ItemNavProps[]) ?? []

  const processed = [...firstLevelItems, ...secondLevelItems, ...thirdLevelItems]?.map((item, index) => ({
    "@type": "SiteNavigationElement",
    position: index + 1,
    name: item?.name ?? null,
    url: item?.link?.href ?? null,
  }))

  return JSON.stringify({
    "@context": "http://schema.org",
    "@type": "ItemList",
    itemListElement: processed?.flat() ?? [],
  })
}
