"use client"

import { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { FillImage } from "~/components/ui/FillImage"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import { AbstractDesktopPanel } from "~/components/abstracts/Navigation/DesktopPanel"
import { useNavigationEvents, usePanelIndex } from "~/components/abstracts/Navigation/hooks"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { SafeArea } from "~/components/globals/Navigation/components/Desktop/ClientDesktopPanel/SafeArea"
import { LinksListWithTitle } from "~/components/globals/Navigation/components/LinksListWithTitle"
import { useCurrentPanel } from "~/managers/NavigationManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ ratio: 0 }, { breakpoint: breakpoints.lg, ratio: 1 / 2 }]

type DesktopPanelProps = PropsWithClassName<{ links: NonNullable<TNavigation>["links"] }>

export function DesktopPanel({ className, links, ...props }: DesktopPanelProps) {
  const [indexPanel, setPanelIndex] = usePanelIndex()
  const currentPanel = useCurrentPanel(links)
  const [currentSublinkIndex, setCurrentSublinkIndex] = useState<number | null>(0)
  const [currentSafeLinkIndex, setCurrentSafeLinkIndex] = useState<number | null>(null)

  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  useNavigationEvents(() => setPanelIndex(null))

  useEffect(() => {
    // I'm sorry I had to do this
    timeoutId.current = setTimeout(() => {
      setCurrentSafeLinkIndex(0)
    }, 100)
    return () => {
      setCurrentSafeLinkIndex(null)
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [indexPanel])

  const parent = useRef<(HTMLDivElement | null)[]>([])
  const child = useRef<(HTMLDivElement | null)[]>([])

  if (!currentPanel) {
    return null
  }

  const image = currentSublinkIndex !== null ? currentPanel.sublinks[currentSublinkIndex]?.image : null

  return (
    <AbstractDesktopPanel className={clsx(className, css.DesktopPanel)} {...props}>
      <div className={clsx(css.DesktopPanelBlock)}>
        <div className={css.smallItem}>
          {currentPanel.sublinks.map(({ link, linksLists, isHighlighted }, index) => {
            const hasLinksLists = linksLists.length > 0
            const isActive = index === currentSublinkIndex

            return (
              <div
                key={`desktop_sublink_${index}`}
                ref={(ref) => {
                  parent.current[index] = ref
                }}
                onMouseEnter={() => {
                  setCurrentSublinkIndex(index)
                  setCurrentSafeLinkIndex(index)
                }}
              >
                {isActive &&
                  currentSafeLinkIndex !== null &&
                  parent.current[currentSafeLinkIndex] !== null &&
                  child.current[currentSafeLinkIndex] !== null && (
                    <SafeArea
                      className={css.safeArea}
                      anchor={parent.current?.[currentSafeLinkIndex] ?? null}
                      submenu={child.current?.[currentSafeLinkIndex] ?? null}
                    />
                  )}
                <InlineCta
                  className={clsx(css.cta, { [css.isFull]: hasLinksLists }, { [css.isActive]: isActive })}
                  size="medium"
                  {...link}
                  withLine={!hasLinksLists}
                >
                  <>
                    <span className={sprinkles({ bgColor: isHighlighted ? "yellow-dolly" : "transparent" })}>
                      {link?.children}
                    </span>
                    {hasLinksLists && (
                      <Icon
                        className={clsx(css.iconCta, css.iconState[`${isActive ? "active" : "inactive"}`])}
                        name="Arrow"
                        direction="right"
                        width={16}
                      />
                    )}
                  </>
                </InlineCta>
              </div>
            )
          })}
        </div>

        <div className={clsx(css.centerItem)}>
          {currentPanel.sublinks.map((sublink, index) => {
            const isActive = index === currentSublinkIndex

            return (
              <div
                key={`links_list_${index}`}
                ref={(ref) => (child.current[index] = ref)}
                className={clsx(css.centerItemContent, "centerItem", { [css.centerItemIsVisible]: isActive })}
              >
                <div className={clsx(css.centerInnerItem)}>
                  {sublink.linksLists.map((linksListWithTitle, subIndex) => (
                    <LinksListWithTitle {...linksListWithTitle} key={`link_list_${index}_${subIndex}`} />
                  ))}
                </div>
              </div>
            )
          })}
        </div>

        <div
          className={css.imageItem}
          onMouseEnter={() => {
            setPanelIndex(null)
            setCurrentSublinkIndex(0)
          }}
        >
          {image && <FillImage asPlaceholder sizesFromBreakpoints={IMAGE_SIZES} {...image} />}
        </div>
      </div>
    </AbstractDesktopPanel>
  )
}
