"use client"

import { NavigationData } from "~/components/abstracts/JSONLD/Navigation"
import { serializeNavigationJsonLd } from "~/components/globals/Navigation/_data/serializer-json-ld"
import { NavigationComponent, type NavigationComponentProps } from "~/components/globals/Navigation/components"

export function Navigation({ navigation }: NavigationComponentProps) {
  if (!navigation) return null

  return (
    <>
      {navigation?.links && <NavigationData {...serializeNavigationJsonLd(navigation.links)} />}
      <NavigationComponent navigation={navigation} />
    </>
  )
}
