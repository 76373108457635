import type { ChangeEvent, Dispatch, SetStateAction } from "react"
import { clsx } from "clsx"
import { FormProvider, useForm } from "react-hook-form"

import type { PropsWithClassName } from "~/@types/generics"
import { DEFAULT_LANG } from "~/lib/i18n/constants"
import Checkbox from "~/components/ui/Checkbox"
import { getAllZonesOptions } from "~/components/ui/LangSwitcher/utils/get-all-zones-options"
import { getLanguagesOptionsByCountry } from "~/components/ui/LangSwitcher/utils/get-languages-options-by-country"
import SquareCta from "~/components/ui/SquareCta"
import Select from "~/components/abstracts/Select"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { arrayMove } from "~/utils/array-move"

import * as css from "./styles.css"

export type SelectorProps = PropsWithClassName<{
  countryState: [string, Dispatch<SetStateAction<string>>]
  languageState: [string, Dispatch<SetStateAction<string>>]
  languagesOptionsState: [
    {
      label: string
      value: string
    }[],
    Dispatch<
      SetStateAction<
        {
          label: string
          value: string
        }[]
      >
    >
  ]
  onClick({ lang, country }: { lang: string; country: string }): void
}>

function Selector({ className, countryState, languageState, languagesOptionsState, onClick }: SelectorProps) {
  const t = useTranslate()
  const zonesOptions = getAllZonesOptions()
  const [countryValue, setCountryValue] = countryState
  const [languageValue, setLanguageValue] = languageState
  const [languagesOptions, setLanguagesOptions] = languagesOptionsState

  const onChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    setCountryValue(e.target.value)

    const options = getLanguagesOptionsByCountry(e.target.value)
    setLanguagesOptions(options)

    if (options?.[0]?.value) {
      setLanguageValue(options?.[0].value)
    }
  }

  const onChangeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    setLanguageValue(e.target.value)
  }

  const methods = useForm({
    defaultValues: {
      country: countryValue,
      lang: languageValue,
    },
  })

  const reorderZonesOptions = arrayMove(
    zonesOptions,
    zonesOptions.findIndex((zone) => zone.value === countryValue),
    0
  )

  return (
    <FormProvider {...methods}>
      <form className={clsx(css.Selector, className)}>
        <p className={css.name}>{t("market_country_label")} :</p>
        <Select
          value={countryValue}
          options={reorderZonesOptions}
          {...methods.register("country", { onChange: onChangeCountry })}
        />
        <p className={css.name}>{t("market_language_label")} :</p>
        <div className={css.lang}>
          {languagesOptions
            // always place default lang (fr) as first option
            // https://app.clickup.com/t/2688547/DAREL-2543?comment=90150068809733
            ?.sort((a) => (a?.value === DEFAULT_LANG ? -1 : 1))
            ?.map((option) => (
              <Checkbox
                type="radio"
                checked={option.value === languageValue}
                key={option.value}
                value={option.value}
                {...methods.register("lang", { onChange: onChangeLanguage })}
              >
                {option.label}
              </Checkbox>
            ))}
        </div>
        <SquareCta
          className={clsx(css.cta)}
          onClick={() => onClick({ lang: languageValue, country: countryValue })}
          theme="backgroundBlack"
        >
          {t("market_submit_label")}
        </SquareCta>
      </form>
    </FormProvider>
  )
}

export default Selector
