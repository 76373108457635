"use client"

import { useRef, useState } from "react"
import { clsx } from "clsx"

import type { Nullish } from "~/@types/generics"
import type { getNostoRecommendationsProductCardSliders } from "~/lib/nosto/queries/apps-api/recommendations/get-pdp-recommendations"
import { useSearchedTerms } from "~/hooks/useSearchedTerms"
import InlineCta from "~/components/ui/InlineCta"
import { useIsSearchPanelOpen } from "~/components/ui/Panels/Search/hooks"
import ProductCardSlider from "~/components/ui/ProductCardSlider"
import { SearchForm } from "~/components/ui/Search/Form"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useInternalRedirects } from "~/providers/InternalRedirectsProvider/index.client"
import { Panel } from "~/managers/PanelManager"
import { usePopin } from "~/managers/PopinManager"

import * as css from "./styles.css"

function SearchPanel({
  data,
}: {
  data: Nullish<Awaited<ReturnType<typeof getNostoRecommendationsProductCardSliders>>>
}) {
  const t = useTranslate()
  const { sendEvent } = useTracking()
  const { current } = usePopin()
  const popinOpen = Boolean(current)
  const [redirects] = useInternalRedirects()
  const { search } = redirects ?? {}

  const [activeTermsSectionIndex, setActiveTermsSectionIndex] = useState<number>(0)
  const panelRef = useRef<HTMLDivElement>(null)

  const [_, setIsSearchPanelOpen] = useIsSearchPanelOpen()

  const { terms: previousTerms } = useSearchedTerms()

  const mostSearchedTerms = (t("popular_searched_terms") ?? "")?.split(", ")?.filter(Boolean) ?? []

  const terms: { label: keyof Dictionary; values: string[] }[] = [
    { label: "search_previous_searched_terms", values: previousTerms },
    { label: "search_most_searched_terms", values: mostSearchedTerms },
  ]

  return (
    <Panel
      className={css.container}
      data-click-outside={"search"}
      zIndex={40}
      clickOutsideRef={popinOpen ? undefined : panelRef}
      onRemove={(e) => {
        if (popinOpen) return
        const target = e?.target as HTMLElement
        if (target?.dataset?.clickOutside === "search" || e === undefined) {
          setIsSearchPanelOpen(false)
          return
        }
      }}
    >
      <div ref={panelRef} className={css.SearchPanel}>
        <SearchForm method="onSubmit" />
        <div className={css.wrapper}>
          <div className={css.searchTerms}>
            <div className={clsx(css.hideOnTablet, css.labels)}>
              {terms
                ?.filter(({ values }) => values?.length > 0)
                ?.map(({ label }, index) => {
                  return (
                    <p
                      key={`searchTermSwitcher-${label}-${index}`}
                      className={clsx(css.label, { [css.inactive]: activeTermsSectionIndex !== index })}
                      onClick={() => setActiveTermsSectionIndex(index)}
                    >
                      {t(label)}
                    </p>
                  )
                })}
            </div>

            {terms
              ?.filter(({ values }) => values?.length > 0)
              ?.map(({ label, values }, index) => {
                return (
                  <div
                    key={`searchTermsGroup-${label}-${index}`}
                    className={clsx({ [css.hideOnMobile]: activeTermsSectionIndex !== index })}
                  >
                    <p className={clsx(css.label, css.hideOnMobile)}>{t(label)}</p>
                    <ul className={clsx(css.list)}>
                      {values?.map((term, index) => (
                        <InlineCta
                          key={`searchedTerm$-${term}-${index}`}
                          className={css.cta}
                          withLine
                          size="small"
                          href={`${search?.href}?q=${term}`}
                          onClick={() => sendEvent("search", { search_term: term })}
                        >
                          <li className={css.term}>{term}</li>
                        </InlineCta>
                      ))}
                    </ul>
                  </div>
                )
              })}
          </div>
          <div className={css.push}>
            {data?.products?.map((product, index) => {
              return <ProductCardSlider key={`recosProduct-${index}`} {...product} widthClassName={css.card} />
            })}
            {/*
            // unavailable feature (for now Nosto can't send us back recommended collections) - collection pushs //
            {Array(3).fill(null).map((_, index) => <PushCollection key={`searchPush-${index}`} className={css.collection} />)}
            */}
          </div>
        </div>
      </div>
    </Panel>
  )
}

export { SearchPanel }
