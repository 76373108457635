"use client"

import { useHydrateAtoms } from "jotai/utils"

import type { TCollectionTree } from "~/managers/CollectionsTreeManager/_data/serializer"
import { collectionTreeAtom } from "~/managers/CollectionsTreeManager/store"

export function CollectionTreeManager({ collectionTree }: { collectionTree: TCollectionTree }) {
  useHydrateAtoms([[collectionTreeAtom, collectionTree]])
  return <></>
}
