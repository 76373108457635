import type { ComponentProps } from "react"
import clsx from "clsx"

import * as css from "./styles.css"
import ad from "./svgs/ad.svg"
import ae from "./svgs/ae.svg"
import af from "./svgs/af.svg"
import ag from "./svgs/ag.svg"
import ai from "./svgs/ai.svg"
import al from "./svgs/al.svg"
import am from "./svgs/am.svg"
import an from "./svgs/an.svg"
import ao from "./svgs/ao.svg"
import aq from "./svgs/aq.svg"
import ar from "./svgs/ar.svg"
import as from "./svgs/as.svg"
import at from "./svgs/at.svg"
import au from "./svgs/au.svg"
import aw from "./svgs/aw.svg"
import ax from "./svgs/ax.svg"
import az from "./svgs/az.svg"
import ba from "./svgs/ba.svg"
import bb from "./svgs/bb.svg"
import bd from "./svgs/bd.svg"
import be from "./svgs/be.svg"
import bf from "./svgs/bf.svg"
import bg from "./svgs/bg.svg"
import bh from "./svgs/bh.svg"
import bi from "./svgs/bi.svg"
import bj from "./svgs/bj.svg"
import bl from "./svgs/bl.svg"
import bm from "./svgs/bm.svg"
import bn from "./svgs/bn.svg"
import bo from "./svgs/bo.svg"
import bq from "./svgs/bq.svg"
import br from "./svgs/br.svg"
import bs from "./svgs/bs.svg"
import bt from "./svgs/bt.svg"
import bv from "./svgs/bv.svg"
import bw from "./svgs/bw.svg"
import by from "./svgs/by.svg"
import bz from "./svgs/bz.svg"
import ca from "./svgs/ca.svg"
import cc from "./svgs/cc.svg"
import cd from "./svgs/cd.svg"
import cf from "./svgs/cf.svg"
import cg from "./svgs/cg.svg"
import ch from "./svgs/ch.svg"
import ci from "./svgs/ci.svg"
import ck from "./svgs/ck.svg"
import cl from "./svgs/cl.svg"
import cm from "./svgs/cm.svg"
import cn from "./svgs/cn.svg"
import co from "./svgs/co.svg"
import cr from "./svgs/cr.svg"
import cu from "./svgs/cu.svg"
import cv from "./svgs/cv.svg"
import cw from "./svgs/cw.svg"
import cx from "./svgs/cx.svg"
import cy from "./svgs/cy.svg"
import cz from "./svgs/cz.svg"
import de from "./svgs/de.svg"
import dj from "./svgs/dj.svg"
import dk from "./svgs/dk.svg"
import dm from "./svgs/dm.svg"
import doFlag from "./svgs/do.svg"
import dz from "./svgs/dz.svg"
import ec from "./svgs/ec.svg"
import ee from "./svgs/ee.svg"
import eg from "./svgs/eg.svg"
import eh from "./svgs/eh.svg"
import er from "./svgs/er.svg"
import es from "./svgs/es.svg"
import et from "./svgs/et.svg"
import eu from "./svgs/eu.svg"
import fi from "./svgs/fi.svg"
import fj from "./svgs/fj.svg"
import fk from "./svgs/fk.svg"
import fm from "./svgs/fm.svg"
import fo from "./svgs/fo.svg"
import fr from "./svgs/fr.svg"
import ga from "./svgs/ga.svg"
import gb from "./svgs/gb.svg"
import gd from "./svgs/gd.svg"
import ge from "./svgs/ge.svg"
import gf from "./svgs/gf.svg"
import gg from "./svgs/gg.svg"
import gh from "./svgs/gh.svg"
import gi from "./svgs/gi.svg"
import gl from "./svgs/gl.svg"
import gm from "./svgs/gm.svg"
import gn from "./svgs/gn.svg"
import gp from "./svgs/gp.svg"
import gq from "./svgs/gq.svg"
import gr from "./svgs/gr.svg"
import gs from "./svgs/gs.svg"
import gt from "./svgs/gt.svg"
import gu from "./svgs/gu.svg"
import gw from "./svgs/gw.svg"
import gy from "./svgs/gy.svg"
import hk from "./svgs/hk.svg"
import hm from "./svgs/hm.svg"
import hn from "./svgs/hn.svg"
import hr from "./svgs/hr.svg"
import ht from "./svgs/ht.svg"
import hu from "./svgs/hu.svg"
import id from "./svgs/id.svg"
import ie from "./svgs/ie.svg"
import il from "./svgs/il.svg"
import im from "./svgs/im.svg"
import inFlag from "./svgs/in.svg"
import io from "./svgs/io.svg"
import iq from "./svgs/iq.svg"
import ir from "./svgs/ir.svg"
import is from "./svgs/is.svg"
import it from "./svgs/it.svg"
import je from "./svgs/je.svg"
import jm from "./svgs/jm.svg"
import jo from "./svgs/jo.svg"
import jp from "./svgs/jp.svg"
import ke from "./svgs/ke.svg"
import kg from "./svgs/kg.svg"
import kh from "./svgs/kh.svg"
import ki from "./svgs/ki.svg"
import km from "./svgs/km.svg"
import kn from "./svgs/kn.svg"
import kp from "./svgs/kp.svg"
import kr from "./svgs/kr.svg"
import kw from "./svgs/kw.svg"
import ky from "./svgs/ky.svg"
import kz from "./svgs/kz.svg"
import la from "./svgs/la.svg"
import lb from "./svgs/lb.svg"
import lc from "./svgs/lc.svg"
import li from "./svgs/li.svg"
import lk from "./svgs/lk.svg"
import lr from "./svgs/lr.svg"
import ls from "./svgs/ls.svg"
import lt from "./svgs/lt.svg"
import lu from "./svgs/lu.svg"
import lv from "./svgs/lv.svg"
import ly from "./svgs/ly.svg"
import ma from "./svgs/ma.svg"
import mc from "./svgs/mc.svg"
import md from "./svgs/md.svg"
import me from "./svgs/me.svg"
import mf from "./svgs/mf.svg"
import mg from "./svgs/mg.svg"
import mh from "./svgs/mh.svg"
import mk from "./svgs/mk.svg"
import ml from "./svgs/ml.svg"
import mm from "./svgs/mm.svg"
import mn from "./svgs/mn.svg"
import mo from "./svgs/mo.svg"
import mp from "./svgs/mp.svg"
import mq from "./svgs/mq.svg"
import mr from "./svgs/mr.svg"
import ms from "./svgs/ms.svg"
import mt from "./svgs/mt.svg"
import mu from "./svgs/mu.svg"
import mv from "./svgs/mv.svg"
import mw from "./svgs/mw.svg"
import mx from "./svgs/mx.svg"
import my from "./svgs/my.svg"
import mz from "./svgs/mz.svg"
import na from "./svgs/na.svg"
import nc from "./svgs/nc.svg"
import ne from "./svgs/ne.svg"
import nf from "./svgs/nf.svg"
import ng from "./svgs/ng.svg"
import ni from "./svgs/ni.svg"
import nl from "./svgs/nl.svg"
import no from "./svgs/no.svg"
import np from "./svgs/np.svg"
import nr from "./svgs/nr.svg"
import nu from "./svgs/nu.svg"
import nz from "./svgs/nz.svg"
import om from "./svgs/om.svg"
import pa from "./svgs/pa.svg"
import pe from "./svgs/pe.svg"
import pf from "./svgs/pf.svg"
import pg from "./svgs/pg.svg"
import ph from "./svgs/ph.svg"
import pk from "./svgs/pk.svg"
import pl from "./svgs/pl.svg"
import pm from "./svgs/pm.svg"
import pn from "./svgs/pn.svg"
import pr from "./svgs/pr.svg"
import ps from "./svgs/ps.svg"
import pt from "./svgs/pt.svg"
import pw from "./svgs/pw.svg"
import py from "./svgs/py.svg"
import qa from "./svgs/qa.svg"
import re from "./svgs/re.svg"
import ro from "./svgs/ro.svg"
import rs from "./svgs/rs.svg"
import ru from "./svgs/ru.svg"
import rw from "./svgs/rw.svg"
import sa from "./svgs/sa.svg"
import sb from "./svgs/sb.svg"
import sc from "./svgs/sc.svg"
import sd from "./svgs/sd.svg"
import se from "./svgs/se.svg"
import sg from "./svgs/sg.svg"
import sh from "./svgs/sh.svg"
import si from "./svgs/si.svg"
import sj from "./svgs/sj.svg"
import sk from "./svgs/sk.svg"
import sl from "./svgs/sl.svg"
import sm from "./svgs/sm.svg"
import sn from "./svgs/sn.svg"
import so from "./svgs/so.svg"
import sr from "./svgs/sr.svg"
import ss from "./svgs/ss.svg"
import st from "./svgs/st.svg"
import sv from "./svgs/sv.svg"
import sx from "./svgs/sx.svg"
import sy from "./svgs/sy.svg"
import sz from "./svgs/sz.svg"
import tc from "./svgs/tc.svg"
import td from "./svgs/td.svg"
import tf from "./svgs/tf.svg"
import tg from "./svgs/tg.svg"
import th from "./svgs/th.svg"
import tj from "./svgs/tj.svg"
import tk from "./svgs/tk.svg"
import tl from "./svgs/tl.svg"
import tm from "./svgs/tm.svg"
import tn from "./svgs/tn.svg"
import to from "./svgs/to.svg"
import tr from "./svgs/tr.svg"
import tt from "./svgs/tt.svg"
import tv from "./svgs/tv.svg"
import tw from "./svgs/tw.svg"
import tz from "./svgs/tz.svg"
import ua from "./svgs/ua.svg"
import ug from "./svgs/ug.svg"
import um from "./svgs/um.svg"
import us from "./svgs/us.svg"
import uy from "./svgs/uy.svg"
import uz from "./svgs/uz.svg"
import va from "./svgs/va.svg"
import vc from "./svgs/vc.svg"
import ve from "./svgs/ve.svg"
import vg from "./svgs/vg.svg"
import vi from "./svgs/vi.svg"
import vn from "./svgs/vn.svg"
import vu from "./svgs/vu.svg"
import wf from "./svgs/wf.svg"
import ws from "./svgs/ws.svg"
import xk from "./svgs/xk.svg"
import ye from "./svgs/ye.svg"
import yt from "./svgs/yt.svg"
import za from "./svgs/za.svg"
import zm from "./svgs/zm.svg"
import zw from "./svgs/zw.svg"

type DynamicIconProps = ComponentProps<"svg"> & { className?: string }

export const FLAGS = {
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  an,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  bq,
  br,
  bs,
  bt,
  bv,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cw,
  cx,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  do: doFlag,
  dz,
  ec,
  ee,
  eg,
  eh,
  er,
  es,
  et,
  eu,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hm,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  im,
  in: inFlag,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  tc,
  td,
  tf,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  um,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  xk,
  ye,
  yt,
  za,
  zm,
  zw,
} as const

export interface FlagProps extends DynamicIconProps {
  name: string
}

function Flag({ name }: FlagProps) {
  const Component = FLAGS?.[name.toLowerCase() as keyof typeof FLAGS] ?? null
  return Component && <Component data-comp={"Flags/Icon"} className={clsx(css.Flag)} />
}

export default Flag
