import { useEffect, type Dispatch, type SetStateAction } from "react"
import { clsx } from "clsx"

import InlineCta from "~/components/ui/InlineCta"
import { getAllCountriesOptions } from "~/components/ui/LangSwitcher/utils/get-all-countries-options"
import SquareCta from "~/components/ui/SquareCta"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type ConfirmProps = {
  country: string
  languageState: [string, Dispatch<SetStateAction<string>>]
  languagesOptions: {
    label: string
    value: string
  }[]
  onClick({ lang, country }: { lang: string; country: string }): void
  onSwitchClick(): void
}

function Confirm({ country, languagesOptions, languageState, onSwitchClick, onClick }: ConfirmProps) {
  const t = useTranslate()
  const countriesOptions = getAllCountriesOptions()
  const [languageValue, setLanguageValue] = languageState

  const currentLanguage = languagesOptions.find((option) => option.value === languageValue)
  const otherLanguage = languagesOptions.filter((option) => option.value !== languageValue)

  const handleOnClick = (lang: string) => {
    setLanguageValue(lang)
    onClick({ lang, country })
  }

  useEffect(() => {
    if (!currentLanguage) onSwitchClick()
  }, [])

  return (
    <>
      <div className={clsx(css.header)}>
        {currentLanguage && (
          <>
            <p className={clsx([text({ design: "neue-13" }), sprinkles({ marginBottom: { mobile: 12, desktop: 3 } })])}>
              {t("market_confirm_lang_sentence", { lang: currentLanguage.label.toLowerCase() })}
            </p>
            <SquareCta onClick={() => handleOnClick(currentLanguage.value)}>
              {t("market_confirm_lang_button", { lang: currentLanguage.label })}
            </SquareCta>
          </>
        )}
        {otherLanguage?.length > 0 &&
          otherLanguage.map(({ value, label }) => (
            <SquareCta key={value} theme="backgroundBlack" onClick={() => handleOnClick(value)}>
              {t("market_switch_lang_button", { lang: label })}
            </SquareCta>
          ))}
      </div>
      <div className={clsx(css.footer)}>
        <p className={clsx([text({ design: "neue-13" })])}>
          {t("market_switch_panel_sentence", { count: countriesOptions.length })}{" "}
          <InlineCta withDefaultLine size="none" onClick={onSwitchClick}>
            {t("market_switch_panel_sentence_button")}
          </InlineCta>
        </p>
      </div>
    </>
  )
}

export default Confirm
