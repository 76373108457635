export function getCurrencyDetails(currencyCode: string) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
  })

  const parts = formatter.formatToParts(1)
  const currencySymbol = parts.find((part) => part.type === "currency")?.value

  const formatterName = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "name",
  })

  const partsName = formatterName.formatToParts(1)
  const currencyName = partsName.find((part) => part.type === "currency")?.value

  return {
    symbol: currencySymbol,
    name: currencyName,
  }
}
