"use client"

import { useEffect, useState } from "react"
import { usePathname, useSearchParams } from "next/navigation"

import useLocale from "~/hooks/useLocale"
import MarketPopin from "~/components/ui/MarketPopin"
import { getMarketRedirect, marketCookie } from "~/components/ui/MarketPopin/utils"
import { usePopin } from "~/managers/PopinManager"

export function useMarketRedirect({ country, lang }: { country: string; lang: string }) {
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  return getMarketRedirect({ lang, country, locale, pathname, searchParams })
}

export function useMarketPopinInit() {
  const { add } = usePopin()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  useEffect(() => {
    if (isReady && !marketCookie.hasInteracted.get()) {
      add(<MarketPopin type="confirm" />)
    }
  }, [isReady])
}
