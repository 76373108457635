import getCurrency from "~/lib/get-currency"
import { getAllZones } from "~/lib/i18n/utils/get-all-zones"
import { getCountryDisplayName } from "~/lib/i18n/utils/get-display-name"
import { getIntl, getLang, getLocaleByCountry } from "~/lib/i18n/utils/get-i18n"
import { getCountryEmojiFromCountryCode } from "~/components/ui/LangSwitcher"
import { getCurrencyDetails } from "~/utils/get-currency-details"

export function getAllZonesOptions() {
  const zones = getAllZones()
  return zones.filter(Boolean).flatMap((country) => {
    const locale = getLocaleByCountry(country)
    if (!locale) return []
    const intl = getIntl(locale)
    const regionCode = intl.split("-")[1]
    const lang = getLang(locale)
    const countryDisplay = getCountryDisplayName(regionCode, lang)
    const currency = getCurrency(locale)
    const currencyDetails = getCurrencyDetails(currency)
    if (!regionCode) return []
    const label = `${getCountryEmojiFromCountryCode(regionCode)} ${countryDisplay} | ${currencyDetails.symbol}`

    if (!label) {
      return []
    }

    return {
      label,
      value: country,
    }
  })
}
