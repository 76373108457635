import serializeJSONLD from "./serialize-json-ld"

export interface ItemNavProps {
  name?: string
  link?: {
    href?: string
    children?: string
  }
  items?: ItemNavProps[]
}

export interface NavigationDataProps {
  items?: ItemNavProps[]
}

export function NavigationData(props: NavigationDataProps) {
  return (
    <script
      id="navigation-json-ld-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: serializeJSONLD(props),
      }}
    />
  )
}
